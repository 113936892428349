import React from "react"
import SEO from "../../components/seo"
import Layout from "../../components/layout";
import { itemListDownload } from "../../utils/sidebar/item-list"
import Container from "../../components/container";
// import Button from "../../components/button";
import presets, { colors } from "../../utils/presets"
import typography, { rhythm, scale } from "../../utils/typography"
import Footer from "../../components/footer";
import TrialHead from "../../components/download/trialHead";
import TrialForm from "../../components/download/trialForm";
import { graphql } from "gatsby"

const UpdateTrial = ({ location, data }) => (
    <Layout location={location} itemList={itemListDownload} contentTitle="资料与软件下载">
        <SEO title="资料与软件下载" />
        {/* <div css={{ ...styles.contentHeader, ...styles.sticky }}>
            <h2 css={styles.contentTitle}>
                <span>
                    资料与软件下载
                </span>
            </h2>
        </div> */}
        <Container overrideCSS={{maxWidth: '100%', padding: 24, minHeight: 'calc(100vh - 392px)'}}>
          <TrialHead />
          {/* <TrialForm /> */}
          <div css={styles.font} dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}></div>
        </Container>
        {/* <div css={styles.footer}> 
          <Footer /> 
        </div>*/}
    </Layout>
)

const styles = {
    cards: {
        background: `#F5F5F5`,
        borderRadius: presets.radiusLg,
        display: `flex`,
        flexWrap: `wrap`,
        width: `100%`,
    },
    contact: {
        flex: `0 0 100%`,
        maxWidth: `100%`,
        padding: rhythm(presets.gutters.default / 2),
        [presets.Hd]: { padding: 24 },
    },
    contentHeader: {
        alignItems: `center`,
        background: `rgba(255,255,255,0.98)`,
        borderBottom: `1px solid ${colors.ui.light}`,
        display: `flex`,
        flexDirection: `row`,
        height: presets.headerHeight,
        paddingLeft: `${rhythm(3 / 4)}`,
        paddingRight: `${rhythm(3 / 4)}`,
        zIndex: 1,
    },
    contentTitle: {
        ...scale(1),
        color: colors.skyDark,
        lineHeight: 1,
        margin: 0,
    },
    footer: {
        background: `dimGray`,
        fontFamily: typography.options.headerFontFamily.join(`,`),
        paddingTop: 0,
        paddingBottom: 0,
    },
    sticky: {
      position: `sticky`,
      top: `calc(${presets.bannerHeight} - 1px)`,
      [presets.Desktop]: {
        top: `calc(${presets.headerHeight} + ${presets.bannerHeight} - 1px)`,
      },
    },
    summaryTitle: {
        margin: `0 0 50px 0`,
        padding: 0,
        alignItems: `center`,
        borderBottom: `1px solid ${colors.ui.border}`,
        display: `flex`,
        flexDirection: `row`,
        height: presets.headerHeight,
        paddingLeft: `${rhythm(3 / 4)}`,
        paddingRight: `${rhythm(3 / 4)}`,
        zIndex: 1,
    },
}

export default UpdateTrial

export const pageQuery = graphql`
  query ProductDownloadInfoQuery {
    markdownRemark(
      frontmatter: { type: { eq: "download-info" } }
    ) {
      html
      frontmatter {
        title
      }
    }
  }
`
