import React from 'react'
import { StaticQuery, graphql } from "gatsby"

const TrialHead = () => (
  <>
    <StaticQuery
      query={pageQuery}
      render={data => {
        return (
          <div dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}></div>
        )
      }}
    />
  </>
)

export default TrialHead

const pageQuery = graphql`
  query ProductTrialQuery {
    markdownRemark(
      frontmatter: { type: { eq: "download-trial" } }
    ) {
      html
      frontmatter {
        title
      }
    }
  }
`
